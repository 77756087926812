import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { useLunr } from 'react-lunr'

// export default ()=>(<div>BB</div>)

export const query = graphql`
  {
    LunrIndex
  }
`
export default ({ data }) => {
  const index = data.LunrIndex.index
  const store = data.LunrIndex.store
  const searchModel = data.LunrIndex.documents
  const [query, setQuery] = useState('日本')
  // const results = useLunr(query, index)
  const results = useLunr(query, index, store)

  if (results.length === 0) {
    console.log('custom search')
    for (const _data of searchModel) {
      for (const key of Object.keys(_data)) {
        const findresult = _data[key].search(query)

        if (findresult >= 0) {
          results.push(store[_data.id])
          break
        }
      }
    }
  }

  console.log(results)
  return (
    <div>
      <h1>Q:{query}</h1>
      <h1>RESULT</h1>
      <ul>
        {results.map((result, index) => (
          <li key={`${result.id}-${index}`} className="text-red-900">
            {result.slug} - {result.title}
          </li>
        ))}
      </ul>
      <hr />
      ... header stuff...
      <pre>{JSON.stringify(results, null, 4)}</pre>
      <pre>{JSON.stringify(data, null, 4)}</pre>
      {/*<Search searchIndex={data.siteSearchIndex.index} />*/}
    </div>
  )
}
